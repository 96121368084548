import get from 'lodash/get'
import merge from 'lodash/merge'
import { stringify } from 'query-string'

export const buildBaseDonateUrl = (child = {}) => {
  return get(child, 'fundraising_page.donation_url', {})
}

export const buildDonateUrl = (child = {}, selfDonate = true, amount) => {
  const basePath = typeof window !== 'undefined' ? window.location.origin : ''

  return [
    buildBaseDonateUrl(child),
    stringify(
      merge(
        {},
        {
          amount: amount || undefined
        },
        {
          exitUrl: `${basePath}/thank_you?donation_id=JUSTGIVING-DONATION-ID`,
          fundraiserName: child.firstname,
          reference: selfDonate ? child.id : undefined
        }
      )
    )
  ]
    .filter(Boolean)
    .join('?')
}
