export default (props, { rhythm, mediaQuery }) => ({
  root: {
    display: 'inline-block'
  },

  shareSection: {
    marginBottom: rhythm(1)
  },

  sectionTitle: {
    position: 'relative',
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '16px',
    color: '#2d3748',
    textAlign: 'left',
    [mediaQuery('sm', 'max-width')]: {
      fontSize: '16px'
    }
  },

  infoIconWrapper: {
    display: 'inline-block',
    marginLeft: '8px',
    width: '32px',
    height: '32px',
    border: '2px solid #2d3748',
    borderRadius: '50%',
    cursor: 'help',
    verticalAlign: 'middle',
    textAlign: 'center'
  },

  divider: {
    height: '1px',
    background: 'rgba(255,255,255,0.25)',
    margin: rhythm([1, 0])
  },

  qrCode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: rhythm(1),
    '> div': {
      width: rhythm(8),
      height: rhythm(8)
    }
  }
})
