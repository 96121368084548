import React, { useState, useRef } from 'react'
import copy from 'copy-to-clipboard'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import useQRCode from '../../hooks/useQRCode'
import Button from '../../../common/components/Button'
import Icon from '../../../common/components/Icon'

const CopyUrl = ({ url, classNames, onClick, showQrCode = false }) => {
  const [copied, setCopied] = useState(false)
  const inputRef = useRef()
  const { qrCodeRef } = useQRCode(url, { width: 50, height: 50 })

  const handleCopyUrl = () => {
    copy(url) && setCopied(true)
    onClick && onClick()
  }

  const handleSelectAll = () => {
    inputRef.current.select()
  }

  return (
    <div className={classNames.root}>
      {showQrCode && (
        <div className={classNames.qrCode}>
          <div ref={qrCodeRef} />
        </div>
      )}
      <input
        className={classNames.input}
        onClick={handleSelectAll}
        readOnly
        ref={inputRef}
        value={url}
      />
      <Button onClick={handleCopyUrl} size={-1}>
        <Icon name={copied ? 'check' : 'link'} />
        <span>{copied ? 'Copied!' : 'Copy'}</span>
      </Button>
    </div>
  )
}

export default withStyles(styles)(CopyUrl)
