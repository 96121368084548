import React from 'react'
import queryString from 'query-string'
import { useMediaQuery } from 'usehooks-ts'
import { breakpoints } from '../../../traits'
import useQRCode from '../../../hooks/useQRCode'
import withStyles from 'constructicon/with-styles'
import ButtonGroup from 'constructicon/button-group'
import ButtonSocial from 'constructicon/button-social'
import ButtonSMS from '../ButtonSMS'
import CopyUrl from '../../CopyUrl'
import styles from './styles'

const ShareSection = ({
  url,
  onShare,
  application,
  classNames
}) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`)
  const { qrCodeRef: mobileQrRef } = useQRCode(url, { width: 100, height: 100 })

  const emailParams = {
    subject: `Help me raise money for my ${application.beneficiary_title}`,
    body: `Here's a link to my page: ${url}`
  }

  return (
    <div className={classNames.shareSection}>
      {isMobile && url && (
        <div className={classNames.qrCode}>
          <div ref={mobileQrRef} />
        </div>
      )}
      <div className={classNames.copyUrlContainer}>
        <CopyUrl
          onClick={onShare}
          showQrCode={!isMobile}
          url={url}
        />
      </div>
      <div onClick={onShare}>
        <ButtonGroup>
          <p className={classNames.sectionTitle}>Share: </p>
          <ButtonSocial
            share
            type='facebook'
            spacing={0.5}
            url={url}
          />
          <ButtonSocial
            share
            title='Please donate or share my page!'
            type='twitter'
            spacing={0.5}
            url={url}
          />
          <ButtonSocial
            share
            type='google'
            spacing={0.5}
            url={url}
          />
          <ButtonSocial
            background='secondary'
            borderColor='secondary'
            type='mail'
            foreground='light'
            href={`mailto:?${queryString.stringify(emailParams)}`}
            spacing={0.5}
            tag='a'
          />
          <ButtonSMS
            url={url}
            message='Please donate or share my page'
          />
        </ButtonGroup>
      </div>
    </div>
  )
}

export default withStyles(styles)(ShareSection)
