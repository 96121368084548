import React, { useState } from 'react'
import withForm from 'constructicon/with-form'
import form from './form'

import EditModal from '../../../common/components/EditModal'
import Form from '../../../common/components/Form'
import InputCurrency from '../../../common/components/InputCurrency'

const EditCashModal = ({ form, onSubmit, onSuccess, onClose }) => {
  const [status, setStatus] = useState(null)
  const [errors, setErrors] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setStatus('fetching')
      const data = await form.submit()
      await onSubmit({ offline_amount_in_cents: Math.round(data.cashAmount * 100) })
      setStatus('fetched')
      onSuccess()
    } catch (error) {
      setStatus('failed')
      setErrors(['Please correct the invalid fields before submitting.'])
      throw error
    }
  }

  return (
    <EditModal
      onClose={onClose}
      open
      title='Edit Cash Amount'
    >
      <Form
        onSubmit={handleSubmit}
        submit='Update'
        actions={[{ label: 'Cancel', onClick: onClose }]}
        isLoading={status === 'fetching'}
        errors={errors}
        noValidate
      >
        <InputCurrency {...form.fields.cashAmount} />
      </Form>
    </EditModal>
  )
}

export default withForm(form)(EditCashModal)
