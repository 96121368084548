import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import first from 'lodash/first'

import DonorAvatar from '../DonorAvatar'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Icon from 'constructicon/icon'
import Pagination from 'constructicon/pagination'
import Section from '../Section'
import Status from '../Status'
import Tooltip from '../Tooltip'
import { showNumberInCurrencyCents } from '../../lib/showNumber'

const DonationFeed = ({
  manualDonations = [],
  child,
  classNames,
  data = [],
  hideSurnames,
  status = 'fetching',
  styles
}) => {
  const allDonations = [
    ...manualDonations,
    ...data
  ]

  if (!allDonations.length) {
    return (
      <Section heading='Donation Feed'>
        <Status
          empty={!allDonations.length}
          emptyMessage='You have not received your first donation'
          status={status}
        />
      </Section>
    )
  }

  return (
    <Pagination
      max={5}
      toPaginate={allDonations}
    >
      {pagination => (
        <Section
          buttons={pagination.isPaginated && [
            { label: 'Prev', onClick: pagination.prev },
            { label: 'Next', onClick: pagination.next }
          ]}
          heading='Donation Feed'
          noBodyPadding
        >
          <Status
            empty={!allDonations.length}
            status={status}
          >
            {pagination.currentPage.map(donation => (
              <div
                className={classNames.root}
                key={donation.id}
              >
                <div className={classNames.donation}>
                  <Grid
                    align='center'
                    spacing={0.25}
                  >
                    <GridColumn
                      xs={2}
                      md={1}
                    >
                      <DonorAvatar
                        alt={donation.name || 'Anonymous'}
                        image={donation.image}
                      />
                    </GridColumn>
                    <GridColumn
                      xs={10}
                      md={3}
                    >
                      {hideSurnames
                        ? first((donation.name || 'Anonymous').split(' '))
                        : donation.name || 'Anonymous'}
                    </GridColumn>
                    <GridColumn
                      xs={8}
                      md={5}
                    >
                      {donation.message}
                    </GridColumn>
                    <GridColumn
                      styles={styles.amount}
                      md={3}
                      xs={4}
                      xsAlign='right'
                    >
                      {donation.amount
                        ? showNumberInCurrencyCents(donation.amount)
                        : (
                          <Tooltip info='The donor opted to hide the donated amount'>
                            <Icon name='info' />
                          </Tooltip>
                          )}
                    </GridColumn>
                  </Grid>
                </div>
                {donation.reply && (
                  <div className={classNames.reply}>
                    <Grid
                      align='center'
                      spacing={0.25}
                    >
                      <GridColumn
                        xs={2}
                        md={1}
                      >
                        <DonorAvatar
                          alt={child.firstname}
                          image={child.image_url}
                        />
                      </GridColumn>
                      <GridColumn
                        xs={10}
                        md={11}
                      >
                        <div className={classNames.replyLabel}>
                          {`${child.firstname} Replied`}
                        </div>
                        {donation.reply}
                      </GridColumn>
                    </Grid>
                  </div>
                )}
              </div>
            ))}
          </Status>
        </Section>
      )}
    </Pagination>
  )
}

export default withStyles(styles)(DonationFeed)
