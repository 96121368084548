export default (props, traits) => {
  const { colors, scale } = traits

  return {
    builderContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '1rem'
    },
    inputContainer: {
      flex: 1,
      position: 'relative'
    },
    qrCode: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '12px'
    },
    characterCount: {
      fontSize: scale(-1),
      color: colors.gray,
      textAlign: 'right',
      marginTop: '-1.25rem',
      marginBottom: '1rem'
    }
  }
}
