export default (props, { rhythm, mediaQuery }) => ({
  shareSection: {
  },

  sectionTitle: {
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '16px',
    color: '#2d3748',
    textAlign: 'left',
    [mediaQuery('sm', 'max-width')]: {
      fontSize: '16px'
    }
  },

  qrCode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: rhythm(1)
  }
})
