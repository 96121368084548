import React, { useState } from 'react'
import InputField from '../../InputField'
import Button from '../../Button'
import ButtonGroup from 'constructicon/button-group'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const MAX_MESSAGE_LENGTH = 160

const FundraisingCardsBuilder = ({ url, name = 'No Name', classNames }) => {
  const [message, setMessage] = useState(
    'Hey there! I am so happy to be fundraising for my school. I would appreciate your help by donating to my page. Thank you for your support.'
  )

  const queryParams = `url=${encodeURIComponent(url)}&message=${encodeURIComponent(message)}&name=${encodeURIComponent(name)}`
  const previewUrl = `/generator/fundraising_card?${queryParams}`
  const pdfUrl = `/generator/fundraising_card.pdf?${queryParams}`

  return (
    <div className={classNames.root}>
      <div className={classNames.builderContainer}>
        <div className={classNames.inputContainer}>
          <InputField
            name='message'
            type='textarea'
            value={message}
            onChange={setMessage}
            rows={4}
            maxLength={MAX_MESSAGE_LENGTH}
            required
          />
          <div className={classNames.characterCount}>
            {MAX_MESSAGE_LENGTH - message.length} / {MAX_MESSAGE_LENGTH}
          </div>
        </div>
      </div>

      <div className={classNames.buttonContainer}>
        <ButtonGroup>
          <Button
            theme='transparentSecondary'
            href={previewUrl}
            spacing={0.5}
            target='_blank'
            tag='a'
          >
            Preview
          </Button>
          <Button
            theme='primary'
            spacing={0.5}
            href={pdfUrl}
            target='_blank'
            tag='a'
          >
            Download
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default withStyles(styles)(FundraisingCardsBuilder)
