import React, { useState } from 'react'
import currentApplication from '../../lib/currentApplication'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../Button'
import Icon from '../Icon'
import Modal from '../Modal'
import Section from '../Section'
import ShareSection from './ShareSection'
import FundraisingCardsBuilder from './FundraisingCardsBuilder'
import Info from '../Info'

const ShareButton = ({
  application = currentApplication,
  children,
  classNames,
  disabled,
  icon = true,
  onClose,
  spacing = { y: 0.5, x: 1 },
  theme = 'secondary',
  url,
  child,
  showFundraisingCardsBuilder = true
}) => {
  const [shared, setShared] = useState(false)
  const [open, setOpen] = useState(false)

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setShared(false)
    onClose && onClose(shared)
  }

  const handleMarkAsShared = () => {
    setShared(true)
  }

  return (
    <div className={classNames.root}>
      <Button
        disabled={disabled}
        onClick={handleOpenModal}
        spacing={spacing}
        theme={theme}
      >
        {icon && <Icon name='share' />}
        <span>{children}</span>
      </Button>
      <Modal
        closeColor='light'
        contentLabel='Share your page to raise money'
        isOpen={open}
        size='small'
        onRequestClose={handleCloseModal}
      >
        <Section
          background='secondary'
          foreground='light'
          heading='Share your page to raise money'
        >
          <ShareSection
            url={url}
            onShare={handleMarkAsShared}
            application={application}
            classNames={classNames}
          />

          {showFundraisingCardsBuilder && (
            <>
              <div className={classNames.divider} />
              <h3 className={classNames.sectionTitle}>
                Generate Fundraising Cards

                <Info
                  styles={{
                    root: {
                      border: '2px solid #2d3748',
                      right: '0px'
                    }
                  }}
                >
                  Fundraising Cards are cards that can be printed and distributed to your friends and neighbours so that they can donate to your page
                </Info>
              </h3>
              <FundraisingCardsBuilder
                url={url}
                name={`${child.public_name} from ${child.event.beneficiary_name}`}
              />
            </>
          )}
        </Section>
      </Modal>
    </div>
  )
}

export default withStyles(styles)(ShareButton)
